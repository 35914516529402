*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
} /*16px*/

:root {
  /* colors */
  --primary-100: #c5e5fc;
  --primary-200: #a5d5f8;
  --primary-300: #8bcbf9;
  --primary-400: #6ebef7;
  --primary-500: #49a6e9;
  --primary-600: #2d87c8;
  --primary-700: #1a6aa2;
  --primary-800: #104e7a;
  --primary-900: #063251;

  /* grey */
  --grey-50: #f8fafc;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --grey-300: #cbd5e1;
  --grey-400: #94a3b8;
  --grey-500: #64748b;
  --grey-600: #475569;
  --grey-700: #334155;
  --grey-800: #1e293b;
  --grey-900: #0f172a;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  --small-text: 0.875rem;
  --extra-small-text: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 600px;

  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
  0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

body {
  background: var(--backgroundColor);
  /*background-image: url('tlo.jpg');*/
  /*background-repeat: no-repeat;*/
  /*background-size: cover;*/
  /*background-attachment: fixed;*/
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1;
  color: var(--textColor);
}

p {
  margin-bottom: 1.5rem;
  /*text-align: justify;*/
  /*max-width: 40em;*/
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: 400;
  line-height: 1;
  /*text-transform: capitalize;*/
  letter-spacing: var(--letterSpacing);
}

h1 {
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text-small {
  font-size: var(--small-text);
}

a {
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
}

.img {
  width: 100%;
  display: block;
  object-fit: cover;
}
/* buttons */

.btn {
  cursor: pointer;
  color: var(--white);
  background: var(--primary-500);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-1);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
}
.btn:hover {
  background: var(--primary-700);
  box-shadow: var(--shadow-3);
}
.btn-hipster {
  color: var(--primary-500);
  background: var(--primary-200);
}
.btn-hipster:hover {
  color: var(--primary-200);
  background: var(--primary-700);
}
.btn-block {
  width: 100%;
}

/* alerts */
.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: var(--borderRadius);
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}
.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}
/* form */

.form {
  width: 90vw;
  max-width: 400px;
  background: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  padding: 2rem 2.5rem;
  margin: 3rem 0;
}
.form-label {
  display: block;
  font-size: var(--small-text);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}
.form-input,
.form-textarea {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  background: var(--backgroundColor);
  border: 1px solid var(--grey-200);
}

.form-row {
  margin-bottom: 1rem;
}

.form-textarea {
  height: 7rem;
}
::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}
.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}
/* alert */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.form h5 {
  text-align: center;
}
.form .btn {
  margin-top: 0.5rem;
}
.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 0.6s linear infinite;
}
.loading {
  margin: 0 auto;
}
/* title */

.title {
  text-align: center;
}

.title-underline {
  background: var(--primary-500);
  width: 7rem;
  height: 0.25rem;
  margin: -1rem auto 0;
}

.section {
  padding: 2rem 0;
  width: 90vw;
  max-width: var(--max-width);
  margin: 0 auto;
}

.navbar {
  width: 90vw;
  max-width: var(--max-width);
  margin: 0 auto;
  display: flex;
  gap: 1rem;
}

.link {
  color: var(--grey-500);
}

.active {
  color: var(--primary-500);
}
.products article {
  margin-bottom: 1rem;
}

.products h5 {
  margin-bottom: 0;
}

.product img {
  width: 200px;
  height: 150px;
}


/* ================ navbar ==================== */

nav {
  background: var(--white);
  box-shadow: var(--shadow-1);
}

.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.nav-toggle {
  font-size: 1.5rem;
  color: var(--primary-500);
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
}

.nav-toggle:hover {
  color: var(--primary-700);
  transform: rotate(90deg);
}

.logo {
  height: 50px;
  margin-right: 20px;
}

.links a {
  color: var(--grey-700);
  font-size: 1rem;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
  display: block;
  padding: 0.5rem 1rem;
  transition: var(--transition);
}

.links a:hover {
  background: var(--primary-100);
  color: var(--primary-500);
  padding-left: 1.5rem;
}

/*  160px */

.links-container {
  overflow: hidden;
  transition: var(--transition);
}

.social-icons {
  display: none;
}

@media screen and (min-width: 800px) {
  .nav-toggle {
    display: none;
  }
  .nav-header {
    padding: 0;
  }
  .nav-center {
    max-width: 1170px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  .links-container {
    height: auto !important;
  }
  .links {
    display: flex;
    gap: 0.5rem;
  }
  .links a {
    padding: 0;
  }
  .links a:hover {
    padding: 0;
    background: transparent;
  }
  .social-icons {
    display: flex;
    gap: 0.5rem;
  }
  .social-icons a {
    color: var(--primary-500);
    transition: var(--transition);
  }
  .social-icons a:hover {
    color: var(--primary-300);
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container li {
  border-radius: 50%;
  background-color: #ccc;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  width: 50px;
  height: 50px;
  margin: 0 5px;
}
