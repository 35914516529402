.container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    outline: none;
    color: #333;
}

.button:hover {
    background-color: #ffff00; /* żółty kolor tła */
}

.date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #7ebff2;
    border-radius: 8px;
    width: 60px;
    height: 40px;
    background-color: #ffffff;
    cursor: pointer;
}

.date:hover{
    background: #b5ccec;
}

.dayOfWeek {
    font-weight: bold;
    text-transform: uppercase;
}

.dateText {
    font-weight: bold;
}

.selected {
    border: 2px solid #7ebff2;
    background-color: #f38863;
}

.buttonHidden {
    opacity: 0;
    pointer-events: none;
}

