.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 95%;
    margin: 0 auto;
}

.tableWrapper {
    width: 100%;
    max-width: 800px;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.formHeader {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.errorMessage {
    color: red;
    font-weight: bold;
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;
}

.formLabel {
    font-weight: bold;
    margin-top: 1rem;
}

.formInput,
.formTextarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    margin-top: 5px;
}

.formTextarea {
    resize: none;
    height: 200px;
}

.submitButton {
    background-color: #007bff;
    color: white;
    padding: 10px;
    margin-top: 1rem;
    font-size: 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submitButton:hover {
    background-color: #0056b3;
}

.submitButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
.newsItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
}

.editButton,
.deleteButton {
    border: none;
    cursor: pointer;
    padding: 5px;
    font-size: 14px;
    transition: all 0.3s;
    background-color: rgba(131, 27, 27, 0.8);
}

.cancelButton {
    border: none;
    cursor: pointer;
    padding: 5px;
    font-size: 14px;
    transition: all 0.3s;
    background-color: #f1612d;
}


.editButton:hover {
    color: #007bff;
}

.deleteButton:hover {
    color: #dc3545;
}


.addButton {
    background-color: #007bff;
    color: white;
    padding: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem; /* Dodane */
    font-size: 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    display: inline-block;
    width: auto;
}


.addButton:hover {
    background-color: #0056b3;
}

.addButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .formContainer {
        padding: 15px;
    }

    .formHeader {
        font-size: 1.25rem;
    }

    .formInput,
    .formTextarea {
        font-size: 0.875rem;
    }

    .submitButton {
        font-size: 0.875rem;
    }

    .newsItem {
        flex-direction: column;
        align-items: flex-start;
    }

    .editButton,
    .deleteButton {
        margin-top: 5px;
        margin-left: 0;
        font-size: 0.875rem;
    }
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
}

.table th,
.table td {
    border: 1px solid #ccc;
    padding: 0.5rem;
    text-align: left;
}

.table th {
    font-weight: bold;
}

.editButton,
.deleteButton {
    border: none;
    cursor: pointer;
    padding: 5px;
    font-size: 14px;
    transition: all 0.3s;
    margin-left: 5px; /* Dodano odstęp między przyciskami */
}

.editButton {
    background-color: #007bff;
    color: white;
}

.editButton:hover {
    background-color: #0056b3;
}

.deleteButton {
    background-color: #dc3545;
    color: white;
}

.deleteButton:hover {
    background-color: #b32a2a;
}
