/* RoundRobinStandings.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.title {
    margin-bottom: 5px;
    margin-top: 10px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

/* Style headers */
.table th {
    background-color: #f2f2f2;
}

/* Center text for specific columns */
.table td:nth-child(1),
.table td:nth-child(3),
.table td:nth-child(4),
.table td:nth-child(5),
.table th:nth-child(1),
.table th:nth-child(3),
.table th:nth-child(4),
.table th:nth-child(5) {
    text-align: center;
}

/* Additional styles for zebra striping, hover effects, etc., if desired */
.table tr:nth-child(even) {background-color: #f2f2f2;}
.table tr:hover {background-color: #ddd;}

/* Ensure table width fills container if not already set */
.table {
    max-width: 600px;
}
