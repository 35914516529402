.resetPasswordForm {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 20px auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
}

.resetPasswordForm input {
    margin-top: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #ddd;
    width: 100%;
    font-size: 0.9rem;
    background-color: #f4f4f4;
    color: #555;
}

.resetPasswordForm button {
    margin-top: 10px;
    padding: 10px;
    background-color: #4caf50;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.resetPasswordForm button:hover {
    background-color: #0078d7;
}

.passwordContainer {
    position: relative;
}

.eyeIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

