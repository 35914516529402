.input {
    margin-top: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #ddd;
    width: 100%;
    font-size: 1.1rem;
    background-color: #f4f4f4;
    color: #555;
}
