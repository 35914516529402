.registerTitle {
    text-align: center;
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-weight: 600;
}

.registerForm {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 20px auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
}


.registerForm .input {
    margin-top: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #ddd;
    width: 100%;
    font-size: 0.9rem;
    background-color: #f4f4f4;
    color: #555;
}

.button {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    border: none;
    background-color: #0077cc;
    font-size: 1rem;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.button:hover {
    background-color: #005fa3;
}

.messageContainer {
    width: 200px;
    margin: 0 auto;
}

.passwordContainer {
    position: relative;
}

.eyeIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.terms {
    margin-top: 20px;
    font-size: 0.9em;
}

.terms input[type="checkbox"] {
    transform: scale(1.5); /* Zwiększa wielkość o 150% */
    margin-right: 10px;
}

.terms label {
    display: flex;
    align-items: center;
    gap: 10px; /* Odstęp między checkboxem a tekstem */
}

.terms a {
    text-decoration: underline;
    color: blue;
    transition: background-color 0.3s ease;
}

.terms a:hover {
    background-color: #91d6e7;
}

.recaptcha {
    margin-top: 20px;
    transform: scale(0.90);
    -webkit-transform: scale(0.90);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
}

.termsLinks {
    display: flex;
    align-items: center;
    gap: 10px;
}

.terms {
    display: flex;
    align-items: start; /* wyrównuje checkbox z górą labela */
    gap: 10px;
}

.termsContent {
    flex-wrap: wrap; /* pozwala tekstu łamać się do następnej linii */
}
