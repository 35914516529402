.container {
    width: 100%; /* Ensures the container takes the full width */
    max-width: 100%; /* Prevents the container from exceeding the viewport width */
    overflow-x: hidden; /* Hides horizontal overflow to prevent horizontal scrolling */
    padding: 20px; /* Provides padding around the content */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .container {
        padding: 10px; /* Reduces padding on smaller screens */
    }

    .tabContent { /* Class you might add to each TabPanel for targeted styling */
        padding: 5px; /* Reduces padding inside tab panels */
        overflow-x: auto; /* Allows horizontal scrolling within tabs if needed */
    }
}