.container {
    margin-bottom: 1rem;
}


.dateInfo {
    display: inline-flex;
    align-items: center;
}

.checkboxAfter14 {
    display: inline-flex;
    align-items: center;
    margin-left: 20px;
}

.checkboxAfter14 label {
    margin-left: 5px;
    margin-right: 10px;
}

.dateAndCheckboxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100%; /* nie pozwala na wyjście poza szerokość ekranu */
    overflow: auto; /* umożliwia przewijanie, gdy zawartość jest za duża */
    flex-wrap: wrap; /* przenosi elementy na nową linię, gdy nie ma wystarczająco dużo miejsca */
}


.blockButton{
    border: 1px solid #ccc;
    margin: 2px;
    border-radius: 6px;
    background: #dc4813;
}

.access {
    margin-top: 20px;
    text-align: center;
}