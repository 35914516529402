.loginTitle {
    text-align: center;
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-weight: 600;
}


.loginForm {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 20px auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
}

.error {
    color: red;
    margin-bottom: 10px;
}

.button {
    margin-top: 10px;
    padding: 10px;
    background-color: #4caf50;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.buttonReset{
    margin-top: 10px;
    padding: 10px;
    background-color: #4caf50;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.button:hover {
    background-color: #347c37;
}

.loginForm .input {
    margin-top: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #ddd;
    width: 100%;
    font-size: 0.9rem;
    background-color: #f4f4f4;
    color: #555;
}


.resetPasswordForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.passwordContainer {
    position: relative;
}


.eyeIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.forgotPassword {
    background: none;
    border: 1px solid #007bff; /* Slight border */
    color: #007bff;
    text-decoration: none; /* Remove underline */
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 20px;
    padding: 5px 10px; /* Add some padding */
    border-radius: 15px; /* Rounded corners */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.forgotPassword:hover {
    background-color: #66aeec;
    color: white;
}

.registerButton {
    background-color: #007bff;
    color: white;
    border: none;
    font-size: 0.9rem;
    margin-top: 10px;
    padding: 7px 10px;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.registerButton:hover {
    background-color: #eae2e2;
    color: #007bff;
    border: 1px solid #007bff;
}

.recaptcha {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    -webkit-transform: scale(0.90);
}