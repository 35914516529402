.podiumContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 20px;
    margin-top: 50px;
}

.podiumStep {
    position: relative;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.winnerName {
    position: absolute;
    top: -30px;
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    max-width: 100%;
}
