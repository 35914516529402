.container {
    background-color: #fff; /* Ensures a clean, bright background */
    max-width: 1800px;
    margin: 20px auto;
    border: 1px solid #eee; /* Lighter border for a softer edge */
    border-radius: 8px; /* Unified border radius for consistency */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    padding: 10px;
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.tabs button {
    background-color: #f9f9f9; /* Lighter color for a neutral look */
    color: #333; /* Dark color for text for readability */
    border: 1px solid #ddd; /* Softer border color */
    padding: 12px 14px; /* Increased padding for a larger clickable area */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-right: 2px; /* Adds space between tabs */
    border-radius: 5px; /* Rounded corners for a friendlier look */
}
.tabs button:hover,
.tabs button.activeTab {
    background-color: #F5A66A; /* More vibrant color for active/hover state */
    color: #fff; /* White text for contrast */
    transform: translateY(-2px); /* Slight raise effect on hover */
}

.tabs button:not(:last-child) {
    border-right: none; /* Make the buttons appear as a single element */
}

