.policyContainer {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 30px auto;
    max-width: 800px;
}

.policyContainer h1, .regulationContainer h4 {
    text-align: center;
}

.policyText {
    margin-top: 20px;
}

.policyText h5 {
    margin-top: 20px;
    color: #333;
}

.policyText p {
    margin-top: 10px;
    text-align: justify;
    color: #666;
}

.policyText p strong {
    color: #ff0000;
}