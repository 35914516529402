.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.title {
    margin-bottom: 15px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
}

.card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.result {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #f9f9f9;
    padding: 10px;
    max-width: 300px;
    align-items: center;
}

.partnerName {
    display: block;
}



.button {
    font-size: small;
    padding: 7px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: darkgray;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.button:hover {
    background-color: #0056b3;
}

.setResults span {
    margin-right: 5px;
    white-space: nowrap;
}

.playerNames div:not(:last-child) {
    margin-bottom: 10px;
}

.resultOfMatchContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.setWinning {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
}


.resultOfMatch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border: none;
    text-align: center;
}


.resultOfMatch div {
    margin-bottom: 10px;
    margin-top: 10px;

}



.resultOfMatchForSingleEliminationBracket {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border: none;
    text-align: center;
}


/*.resultOfMatchForSingleEliminationBracket div:not(:last-child) {*/
/*    margin-bottom: 10px;*/
/*    margin-top: 5px;*/
/*}*/

.resultOfMatchForSingleEliminationBracket div {
    margin-bottom: 10px;
    margin-top: 10px;
}

@media (max-width: 600px) {
    .gridContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card {
        width: 100%;
        max-width: 400px;
    }
}
