.instructionsContainer {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 30px auto;
    max-width: 800px;
}

.instructionsContainer h1 {
    text-align: center;
}

.instructionText {
    margin-top: 20px;
    clear: both;
}

.instructionText h5 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #333;
}

.instructionText p {
    max-width: none;
    margin-top: 10px;
    text-align: justify;
    color: #666;
}

.instructionText img.floatingImageLeft {
    max-width: 500px;
    max-height: 400px;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
}

.instructionText img.floatingImageRight {
    max-width: 500px;
    max-height: 400px;
    float: right;
    margin-left: 15px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
}

.imageContainer {
    clear: both; /* Czyści floaty wewnątrz kontenera */
    overflow: hidden; /* Dodatkowa ochrona przed wychodzeniem obrazów poza kontener */
}

.clear {
    clear: both;
}

@media (max-width: 600px) {

    .instructionText img.floatingImageRight, .instructionText img.floatingImageLeft {
        max-width: 100%;
    }

}
