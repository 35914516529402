.tournaments {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Adjusts the space between each link/card */
    }

.tournaments a {
    background-color: #f0f0f0; /* Light gray background */
    color: #333; /* Dark text color for contrast */
    padding: 15px 25px; /* Padding inside each link/card */
    border-radius: 8px; /* Rounded corners for the card-like appearance */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 80%; /* Or a specific width, adjust as necessary */
    text-align: center; /* Centers the text within each link/card */
    text-decoration: none; /* Removes the underline from links */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
}

.tournaments a:hover {
    background-color: #e0e0e0; /* Slightly darker background on hover */
    transform: translateY(-5px); /* Lifts the card up a bit */
}


/* Add to TourReg.module.css based on the styles from Info.module.css */
.tournamentButton {
    /*background-color: #f0f0f0;*/
    background-color: lightgoldenrodyellow;
    color: #333;
    padding: 15px 25px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80%;
    text-align: center;
    border: none; /* Remove default button border */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    max-width: 300px;
    display: block; /* Ensure full width */
}

.tournamentButton:hover {
    background-color: yellowgreen;
    transform: translateY(-5px);
}

.inactive {
    background-color: #cccccc; /* Grayed out for inactive */
    /*cursor: not-allowed; !* Change cursor to indicate it's not clickable *!*/
    box-shadow: none; /* No shadow for inactive buttons */
    transform: none; /* No transform effect on hover */
}

.tournamentButton:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.noTournamentsMessage {
font-size: 1.2rem;
}

.isLoading {
    text-align: center;
}

.filterButtonsContainer {
    display: flex;
    justify-content: center; /* Wyśrodkowanie w poziomie */
    align-items: center;    /* Wyśrodkowanie w pionie */
    margin-bottom: 16px;
    flex-wrap: wrap;        /* Pozwala na zawijanie przycisków, jeśli jest ich zbyt wiele */
}

.yearButton {
    margin: 4px;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    background-color: #f0f0f0;
    color: #333;
    border-radius: 4px; /* Dodanie zaokrąglonych rogów */
    transition: background-color 0.2s ease;
}

.yearButton:hover {
    background-color: #e0e0e0; /* Zmiana koloru przy najechaniu kursorem */
}

.yearButton.active {
    background-color: #007bff; /* Kolor dla aktywnego przycisku */
    color: white;
    font-weight: bold;
}