.modalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.modalMessage {
    font-size: 1.1rem;
    margin-bottom: 3px;
    text-transform: none;
    text-align: center;
}

.modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*background-color: white;*/
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    outline: none;
    border: none;
    z-index: 10000;
    max-width: 250px;
    word-wrap: break-word;
    margin: 0 auto;
}

.modalButton {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 6px 14px;
    /*text-align: center;*/
    text-decoration: none;
    display: block;
    font-size: 1rem;
    margin: 14px auto 0;
    cursor: pointer;
    border-radius: 4px;
    width: fit-content;
    height: 40px;
}

.buttonPositive {
    background-color: green;
}

.buttonNegative {
    background-color: red;
}
