.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.tabs button {
    background-color: #f9f9f9; /* Lighter color for a neutral look */
    color: #333; /* Dark color for text for readability */
    border: 1px solid #ddd; /* Softer border color */
    padding: 12px 24px; /* Increased padding for a larger clickable area */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-right: 2px; /* Adds space between tabs */
    border-radius: 5px;/* Rounded corners for a friendlier look */
}

.tabs button:hover,
.tabs button.activeTab {
    background-color: #F5A66A; /* More vibrant color for active/hover state */
    color: #fff; /* White text for contrast */
    transform: translateY(-2px); /* Slight raise effect on hover */
}

.tabs button:not(:last-child) {
    border-right: none; /* Make the buttons appear as a single element */
}


.container {
    background-color: #fff; /* Ensures a clean, bright background */
    max-width: 800px;
    margin: 20px auto;
    border: 1px solid #eee; /* Lighter border for a softer edge */
    border-radius: 8px; /* Unified border radius for consistency */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}


/* Visibility toggle for phone and email */
.visibilityToggle {
    display: none;
}

.visibilityToggle.active {
    display: block;
}

.feesTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.feesTable th,
.feesTable td {
    padding: 10px;
    border: 1px solid #ddd;
}

.feesTable th:first-child,
.feesTable td:first-child {
    text-align: left;
}

.feesTable th {
    background-color: #f2f2f2;
    text-align: center;
}

.feesTable td {
    text-align: center;
}


.about,
.fees,
.legend {
    /*margin-bottom: 1rem;*/
    overflow: auto;
}

.paymentOptions {
    /*width: 85%;*/
    margin-top: 0;
    overflow: auto;
}

.paymentOptions h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.paymentOptions p {
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
}

.paymentOptions p:last-child {
    margin-bottom: 0.5rem;
}

.przelewy24,
.bankTransfer {
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
}

.przelewy24 img,
.bankTransfer img {
    width: 100px;
    margin-right: 1rem;
}

@media (max-width: 768px) {
    .paymentOptions {
        width: 98%;
    }

    .paymentOptions h2 {
        font-size: 1.25rem;
    }

    .paymentOptions p {
        font-size: 0.875rem;
    }

    .przelewy24,
    .bankTransfer {
        flex-direction: column;
        align-items: flex-start;
    }

    .przelewy24 img,
    .bankTransfer img {
        margin-right: 0;
        margin-bottom: 1rem;
        width: 80px;
    }
}

.rightAligned {
    text-align: right;
}

.przelewy24,
.bankTransfer {
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
}

.regulations {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Adjusts the space between each link/card */
}

.regulations a {
    background-color: #f0f0f0; /* Light gray background */
    color: #333; /* Dark text color for contrast */
    padding: 15px 25px; /* Padding inside each link/card */
    border-radius: 8px; /* Rounded corners for the card-like appearance */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 100%; /* Or a specific width, adjust as necessary */
    text-align: center; /* Centers the text within each link/card */
    text-decoration: none; /* Removes the underline from links */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
}

.regulations a:hover {
    background-color: #e0e0e0; /* Slightly darker background on hover */
    transform: translateY(-5px); /* Lifts the card up a bit */
}

.contactLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Space between each contact method */
}

.contactLinks a, .contactLinks .coachLink {
    display: flex;
    align-items: center; /* Aligns icon and text vertically */
    gap: 10px; /* Space between the icon and text */
    background-color: #f0f0f0;
    color: #333;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%; /* Adjust based on preference */
    text-align: left;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
}

.contactLinks a:hover, .contactLinks .coachLink:hover {
    background-color: #e0e0e0;
    transform: translateY(-5px);
    text-decoration: none; /* Ensures no underline appears on hover */
}

.contactLinks a svg, .contactLinks .coachLink svg {
    margin-right: 5px; /* Ensures a small gap between the icon and text for better readability */
}

.text {
    text-align: justify;
}

.isLoading {
    text-align: center;
    margin-top: 20px;
}