.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: rgba(217, 223, 229, 0.51);
    border-radius: 10px;
    margin: 20px auto;
    width: 80%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}

.info p {
    margin: auto 0;
}


@media (min-width: 1024px) {
    .info {
        width: 80%;
        margin: 0 auto;
    }
}

.button {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.buttonAll {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #379a52;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    float: right;
    margin-bottom: 20px;
}

.MyPayments {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    overflow-x: auto;
}


.memberInfo {
    display: inline-flex; /* Use flex to align icon and text */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    background-color: #6fcb73; /* A pleasant green; adjust based on your theme */
    color: white; /* White text for contrast */
    border: none; /* Remove default border */
    padding: 10px 20px; /* Adequate padding for visual comfort */
    border-radius: 5px; /* Slightly rounded corners for a modern look */
    cursor: none; /* Change cursor to indicate it's clickable */
    font-size: 13px; /* Readable text size */
    gap: 10px; /* Space between the icon and the text */
    /*transition: background-color 0.3s, transform 0.2s; !* Smooth transitions for interactions *!*/
    transform: scale(1.05); /* Slight increase in size to indicate interactivity */
    outline: none; /* Remove focus outline for aesthetics; consider replacing for accessibility */
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #4CAF50; /* Custom focus style */
    margin-bottom: 30px;
}


.memberInfo:hover {
    background-color: #6fcb73;
}

.payedToDate {
    display: inline-flex; /* Use flex to align icon and text */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    background-color: dodgerblue; /* A pleasant green; adjust based on your theme */
    color: white; /* White text for contrast */
    border: none; /* Remove default border */
    padding: 10px 20px; /* Adequate padding for visual comfort */
    border-radius: 5px; /* Slightly rounded corners for a modern look */
    cursor: none; /* Change cursor to indicate it's clickable */
    font-size: 16px; /* Readable text size */
    gap: 10px; /* Space between the icon and the text */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transitions for interactions */
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #4CAF50;
}

.payedIcons {
    display: flex;
    gap: 20px;
    font-size: 1rem;
}

.payedIcons > svg {
    transition: transform 0.2s ease;
}

.noUnpaidReservations {
    color: green;
    font-size: 1.2em;
    margin-top: 20px;
}

.tableContainer {
    width: 100%
}

.tableContainer p {
    margin-top: 20px;
}


@media (min-width: 1024px) {
    .tableContainer {
        width: 80%;
        margin: 0 auto;
    }
}

.reservationsTable {
    width: 100%;
    border-collapse: collapse;
}

.reservationsTable th,
.reservationsTable td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.reservationsTable th {
    background-color: #f2f2f2;
}

.reservationsTable tr:hover {
    background-color: #ddd;
}

.slider {
    width: 100%;
}

.sliderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sliderLabels {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.periodicPaymentButton {
    display: block;
    margin: 20px auto; /* Odsuwa przycisk o 20px w górę i na dół i wyśrodkowuje go w poziomie */
    text-align: center; /* Wyśrodkowuje tekst w przycisku */
    font-size: 1.2em;
}

.periodicTitle {
    margin: 50px auto 20px auto;
    font-size: 1em;
}

.paymentInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.paymentInfo img {
    max-width: 40%;
    display: block;
    margin: 0 auto;
}

.emailLink {
    /*display: flex;*/
    /*align-items: center;*/
    margin-left: 1rem; /* Zmienia odstęp od góry */
    /*margin-bottom: 1rem; !* Zmienia odstęp od dołu *!*/
}

.emailLink svg {
    margin-right: 1rem; /* Odstęp między ikoną a tekstem */
}


.memberFeeButton {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 30px;
    background-color: lightgray;
    color: #6f6d63;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.memberFeeButton:hover {
    background-color: #0056b3; /* darker shade for hover effect */
    color: white;
}

.isLoading {
text-align: center;
}

.paymentHistoryButton {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: dodgerblue;
    color: white;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.paymentHistoryButton:hover {
    background-color: #0056b3; /* darker shade for hover effect */
}
