.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.title {
    font-size: 20px;
    margin-bottom: 10px;
}

.addMeButton {
    background-color: #388eea;
    color: white;
    border-radius: 5px;
}

.table {
    max-width: 800px;
    border-collapse: collapse;
    margin-top: 20px;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f9f9f9;
}

.table tr:nth-child(odd) {
    background-color: #fcfcfc;
}

.table tr:hover {
    background-color: #f1f1f1;
}

.table th:nth-child(1),
.table th:nth-child(2),
.table td:nth-child(1),
.table td:nth-child(2) {
    text-align: center;
}

.buttonAdd {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.buttonAdd:hover, .buttonUpdate:hover {
    background-color: #0056b3;
}
.buttonDelete {
    background-color: #dc3545;
}

.buttonDelete:hover {
    filter: brightness(90%);
}

.buttonDelete {
    display: block; /* Makes the button a block element */
    /*margin-bottom: 1px; !* Adds space below each button except the last one *!*/
    text-align: center; /* Centers the text inside the button */
    font-size: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: filter 0.2s ease-in-out;
}

.userIdInput {
    margin-right: 10px; /* Adds space to the right of the input field */
    padding: 8px; /* Adjust padding as needed for consistency with button */
    border-radius: 5px; /* Optional: to match the button's border radius */
    border: 1px solid #ddd; /* Optional: to match the table's style */
}

.addParticipantContainer {
    display: flex; /* Aligns items horizontally */
    flex-direction: column;
    gap: 10px;
    /*align-items: center; !* Centers items vertically *!*/
    justify-content: center; /* Centers the container's content horizontally */
}

.competitionSelect {
    padding: 8px;
    margin-right: 10px; /* Space between select and start button */
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px; /* Adjust based on preference */
    cursor: pointer; /* Indicates the element is interactive */
    background-color: white; /* Matches the form background */
    color: #333; /* Text color for select */
}

.startTournamentButton {
    background-color: #4CAF50; /* A green color indicating a positive action */
    color: white;
    border-radius: 5px;
    padding: 10px 20px; /* Adequate padding for visual comfort */
    border: none; /* No border for a modern look */
    cursor: pointer; /* Indicates the element is clickable */
    font-size: 16px; /* Readable text size */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.startTournamentButton:hover {
    background-color: #45a049; /* Slightly darker green on hover */
}

.deleteMatchesButton{
    background-color: red; /* A green color indicating a positive action */
    color: white;
    border-radius: 5px;
    padding: 10px 20px; /* Adequate padding for visual comfort */
    border: none; /* No border for a modern look */
    cursor: pointer; /* Indicates the element is clickable */
    font-size: 16px; /* Readable text size */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    margin-left: 10px;
}

.tournamentControls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px; /* Space above the controls */
}

.seedNumberInput {
    width: 50px; /* Adjust the width as needed */
}

.buttonDelete {
    margin-left: 10px; /* Adds space between the seed field and button */
}

.actionColumn {
    display: flex;
    justify-content: space-between; /* Adjusts for space between seed # and button */
}

/* Partner email input field */
.partnerEmailInput {
    padding: 10px; /* Ample padding for easy interaction */
    border: 1px solid #c4c4c4; /* Light border for subtle emphasis */
    border-radius: 5px; /* Matching border-radius for consistency */
    font-size: 16px; /* Comfortable font size for readability */
    margin-top: 5px; /* Slight separation from the label or previous content */
    margin-bottom: 10px; /* Space before any following content */
    width: 100%; /* Full-width to match other inputs, adjust as necessary */
    box-sizing: border-box; /* Ensures padding does not affect the overall width */
    transition: border-color 0.2s ease-in-out; /* Smooth transition for focus */
}

.partnerEmailInput:focus {
    border-color: #388eea; /* Highlight color for focus, adjust as needed */
    outline: none; /* Remove default focus outline */
}

.partnerEmailInput::placeholder {
    color: #a8a8a8; /* Placeholder text color, adjust as needed */
}

.partnerEmailInput:invalid {
    border-color: #e74c3c; /* Error color if the input content is invalid */
}