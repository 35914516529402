
.reserveButton {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    border: orangered;
    background-color: rgba(255, 69, 0, 0.5);
    color: white;
    border-radius: 8px;
    padding: 5px 10px;
    transition: background-color 0.3s, color 0.3s;
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reserveButton {
    font-size: 1.2rem;
}

.reserveButton:hover {
    background-color: #4fbfda;
    color: rgba(255, 69, 0, 1);
}



.mainContainer {
    display: flex;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: row;
}

.courtRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}


.courtName {
    width: 115px;
    text-align: right;
    padding-right: 10px;
    position: relative;
    z-index: 1;
    font-weight: bold;
    margin-bottom: 5px;
}

.timeAndCells {
    display: flex;
    position: relative;
}

.timeRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}

.timeRow::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.cellsRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.legend {
    display: flex;
    justify-content: center;
}

.legend button{
    padding: 7px 5px;
    font-size: 0.6em;
    color: #1e293b;
    border: 1px solid;
    border-radius: 8px;
    cursor: none;
    margin-right: 5px;
    float: right;
}

.member {
    background-color: lightgreen;
}

.periodic {
    background-color: lightblue;
}

.blocked {
    background-color: orangered;
}

.registered {
    background-color: yellow;
}

.payed {
    background-color: pink;
}

.lessons {
    background-color: #e8e8e8;
}

.nmember {
    background-color: darkseagreen;
}