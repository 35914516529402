/*MemberFee.module.css*/

.memberFeeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px auto;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.feeTypeCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
}

.feeTypeCard:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.feeTypeTitle {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}

.feeDetails {
    text-align: center;
    margin-bottom: 10px;
}

.radioGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
}

.radioInput {
    margin-right: 10px;
}

.radioLabel {
    margin-right: 20px;
    font-size: 1em;
}

.submitButton {

    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2em;
    border-radius: 4px;
    cursor: pointer;
}

.submitButton:hover {
    background-color: #45a049;
}

.paymentInfo {
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.paymentInfo .przelewy24Logo {
    max-width: 150px; /* Adjust this value as needed */
    height: auto; /* This ensures the image maintains its aspect ratio */
}


/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .memberFeeContainer {
        margin: 10px;
        width: auto;
    }

    .terms input[type="checkbox"] {
        transform: scale(1);
        margin-right: 10px;
    }


    .feeTypeTitle {
        font-size: 1em;
    }

    .radioGroup {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .radioLabel {
        margin: 5px 0;
    }

    .submitButton {
        padding: 8px 16px;
        font-size: 1.4em;
    }
}

.terms {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    margin: 20px 0;
    gap: 10px;
}

.terms input[type="checkbox"] {
    transform: scale(1.5);
    margin-right: 10px;
}

.terms label {
    display: flex;
    align-items: center;
    gap: 10px;
}

.terms a {
    text-decoration: underline;
    color: blue;
    transition: background-color 0.3s ease;
}

.terms a:hover {
    background-color: #91d6e7;
}